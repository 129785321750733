<template>
  <div class="box">
    <div class="min-box">
      <div>战略规划</div>
      <img src="@/assets/images/jia.png" alt="" />
      <div>顶层设计</div>
      <img src="@/assets/images/jia.png" alt="" />
      <div>评估咨询</div>
    </div>
    <div class="min-box">
      <div>
        <span class="span">建设运营</span>
        <span>一体化</span>
      </div>
      <img src="@/assets/images/jia.png" alt="" />
      <div>
        <span class="span">数字城市</span>
        <span>生态运营</span>
      </div>
      <img src="@/assets/images/jia.png" alt="" />
      <div>资源整合</div>
    </div>
    <div class="title">
      <h1>一体化服务模式</h1>
      <h5>咨询规划—实施集成—运营维护”一体化的服务模式</h5>
    </div>
    <!-- <Title h1="一站式顶层设计服务 " h5="全生态新型AI智慧城市整体解决方案"/> -->
  </div>
</template>

<script>
// import Title from './Title.vue'
export default {
  name: 'Advantage2',
  components: {
    // Title
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.box {
  height: 5.62rem;
  background: #ffffff;
  box-shadow: 1px 1px 10px 10px rgba(192, 192, 192, 0.28);
  border-radius: 0rem;
  margin: auto 0.3rem;
  padding: 1.06rem 0 0rem 0;
}
.min-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 0.37rem;
}
.min-box > div {
  width: 1.4rem;
  height: 1.4rem;
  background: #08467c;
  opacity: 0.8;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: 0.24rem;
  font-weight: bold;
  color: #ffffff;
  flex-wrap: wrap;
  line-height: 1.4rem;
}
.min-box > img {
  width: 0.35rem;
  height: 0.3rem;
}

h1 {
  font-size: 0.46rem;
  font-weight: 400;
  color: #08467c;
  text-align: center;
}
h5 {
  font-size: 0.26rem;
  font-weight: 300;
  color: #666666;
  text-align: center;
}
.span {
  display: block;
  padding-top: 0.45rem;
}
span {
  line-height: 1;
}
</style>
