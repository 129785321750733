<template>
  <div class="servicer-box">
    <div
      class="img-box"
      v-for="(item, index) in imgs"
      :key="index"
      @click="active(index)"
    >
      <img class="img" :src="item.img" alt="智慧城市" />
      <span class="text">{{ item.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Field',
  data() {
    return {
      imgs: [
        {
          img: require('@/assets/images/zhihuichengshi.png'),
          text: 'AI智慧城市'
        },
        {
          img: require('@/assets/images/chengshihulianwang.png'),
          text: '城市互联网'
        },
        {
          img: require('@/assets/images/more.png'),
          text: '更多产品'
        }
      ]
    }
  },
  methods: {
    active(index) {
      if (index === 0) {
        this.$router.push({ path: '/product' })
      } else if (index === 1) {
        this.$router.push({ path: '/product' })
      } else {
        this.$router.push({ path: '/product' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.servicer-box {
  padding: 0.7rem;
  padding-bottom: 1.03rem;
}
.img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.12rem;
  background: #ffffff;
  border: 2px solid #08467c;
  box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.06);
  margin: 0 auto 0.37rem;
  box-sizing: border-box;
}
.img {
  width: 0.75rem;
  height: 0.88rem;
  margin-right: 0.29rem;
}
.text {
  font-size: 0.34rem;
  font-weight: 400;
  color: #333333;
}
</style>
