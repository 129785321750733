<template>
  <div id="navbar">
    <!-- 导航栏 -->
    <!-- <Navigation /> -->

    <!-- 内容区域 -->
    <!-- 轮播图 -->
    <Swper />
    <!-- 服务领域 -->

    <Title h1="服务领域" h5="快速规范并高质量地打造各种智慧城市信息化系统" />
    <Field />
    <!-- 服务优势 -->
    <div class="back-color">
      <Title
        h1="服务优势"
        h5="创新、诚信、高效、协作"
        h6="满足客户的一切需求是中天云网永远的追求"
      />
      <Advantage class="marg" />
      <Advantage2 class="marg" />
      <Advantage3 />
    </div>
    <!--案例-->
    <!-- <Title h1="经典案例" h5="快速规范并高质量地打造各种智慧城市信息化系统" />
    <Case /> -->
    <!--新闻资讯-->
    <Title h1="新闻资讯" h5="实时更新公司最新动态资讯" />
    <Message />
    <!--底部-->
    <Foot />
  </div>
</template>

<script>
// import Navigation from '@/components/Navigation.vue'
import Swper from './components/Swper.vue'
import Title from './components/Title.vue'
import Field from './components/Field.vue'
import Advantage from './components/Advantage.vue'
import Advantage2 from './components/Advantage2.vue'
import Advantage3 from './components/Advantage3.vue'
// import Case from './components/Case.vue'
import Message from './components/Message.vue'
import Foot from '@/components/Foot.vue'
export default {
  name: 'Home',
  components: {
    // Navigation,
    Foot,
    Swper,
    Title,
    Field,
    Advantage,
    Advantage2,
    Advantage3,
    // Case,
    Message
  },
  data() {
    return {}
  }
  // mounted: {}
}
</script>

<style lang="scss" scoped>
.back-color {
  background-color: #f4f4f4;
  padding-bottom: 1.16rem;
}
.marg {
  margin-bottom: 0.43rem;
}
</style>
