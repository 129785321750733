<template>
  <div class="box">
    <div class="min-box">
      <div>总体规划</div>
      <img src="@/assets/images/jt.png" alt="" />
      <div>顶层设计</div>
      <img src="@/assets/images/jt.png" alt="" />
      <div>项目建议书</div>
      <img src="@/assets/images/jt.png" alt="" />
      <div>可行性研究方案</div>
      <img src="@/assets/images/jt.png" alt="" />
      <div>初步设计方案</div>
    </div>
    <div class="title">
      <h1>一站式顶层设计服务</h1>
      <h5>全生态新型AI智慧城市整体解决方案</h5>
    </div>
    <!-- <Title h1="一站式顶层设计服务 " h5="全生态新型整体解决方案"/> -->
  </div>
</template>

<script>
// import Title from './Title.vue'
export default {
  name: 'Advantage',
  components: {
    // Title
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.box {
  height: 5.07rem;
  background: #ffffff;
  box-shadow: 1px 1px 10px 10px rgba(192, 192, 192, 0.28);
  border-radius: 0rem;
  margin: auto 0.3rem;
  padding: 1.06rem 0 0rem 0;
}
.min-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 0.37rem;
}
.min-box > div {
  width: 0.76rem;
  height: 2.45rem;
  background: #08467c;
  opacity: 0.8;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  font-size: 0.26rem;
  font-weight: bold;
  color: #ffffff;
  writing-mode: vertical-rl;
  line-height: 0.76rem;
}
.min-box > img {
  width: 0.35rem;
  height: 0.3rem;
}

h1 {
  font-size: 0.46rem;
  font-weight: 400;
  color: #08467c;
  text-align: center;
}
h5 {
  font-size: 0.26rem;
  font-weight: 300;
  color: #666666;
  text-align: center;
}
</style>
