<template>
  <div class="box">
    <div>
      <img class="kj" src="@/assets/images/kj.png" alt="" />
    </div>
    <div class="title">
      <h1>AI智慧城市建设框架</h1>
      <h5>AI智慧城市为核心的产品能力体系</h5>
    </div>
    <!-- <Title h1="一站式顶层设计服务 " h5="全生态新型AI智慧城市整体解决方案"/> -->
  </div>
</template>

<script>
// import Title from './Title.vue'
export default {
  name: 'Advantage2',
  components: {
    // Title
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.box {
  height: 6.34rem;
  background: #ffffff;
  box-shadow: 1px 1px 10px 10px rgba(192, 192, 192, 0.28);
  border-radius: 0rem;
  margin: auto 0.3rem;
  padding: 1.06rem 0 0rem 0;
}

h1 {
  font-size: 0.46rem;
  font-weight: 400;
  color: #08467c;
  text-align: center;
}
h5 {
  font-size: 0.26rem;
  font-weight: 300;
  color: #666666;
  text-align: center;
}
.kj {
  width: 6.56rem;
  height: 4.83rem;
  display: block;
  margin: auto;
}
</style>
