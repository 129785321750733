
<template>
  <div class="title">
    <h1>{{ h1 }}</h1>
    <h5>{{ h5 }}</h5>
    <h5>{{ h6 }}</h5>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: ['h1', 'h5', 'h6'],
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.title {
  padding: 1.06rem 0 0.6rem 0;
}
h1 {
  font-size: 0.5rem;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
h5 {
  font-size: 0.26rem;
  font-weight: 300;
  color: #666666;
  text-align: center;
}
</style>
